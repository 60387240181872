import '@hotwired/turbo-rails'
import 'arrive'
import * as bootstrap from 'bootstrap'
import sal from 'sal.js'

// stimulus support
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
const application = Application.start()
const controllers = import.meta.glob('~/**/*_controller.js', { eager: true })
registerControllers(application, controllers)

// 100vh hack for mobiles
// https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
// function setVhHeight() { document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`) }

// side navi margin hack
function setNaviMargin() {
  const sliderNavi = document.querySelector('.slider-navi')
  if (sliderNavi) {
    const containerWidth = 1200
    const sideSpace = (window.innerWidth - containerWidth) / 2
    const margin = (sideSpace - sliderNavi.offsetWidth) / 2
    document.documentElement.style.setProperty('--navi-margin',`${margin}px`)
  }
}

visualViewport.addEventListener('resize', () => {
  // setVhHeight()
  setNaviMargin()
})

visualViewport.addEventListener('orientationchange', () => {
  // setVhHeight()
  setNaviMargin()
})

// main menu mobile collapsing
document.arrive('#menu-inner', { existing: true }, function(el) {
  ['show.bs.collapse', 'hide.bs.collapse'].forEach(function(evt){
    el.addEventListener(evt, function(event){
      document.querySelectorAll('.toggle-icon').forEach(function(el){ el.classList.toggle('d-none') })
    })
  })
})

document.arrive('nav.menu a', { existing: true }, function(el) {
  el.addEventListener('click', function(event) {
    var menu = document.querySelector('#menu-inner')
    var coll = bootstrap.Collapse.getInstance(menu)
    if (coll) { coll.hide() }
  })
})

// scroll to anchor on load when needed
function scrollToAnchor() {
  if (window.location.hash) {
    var el = document.querySelector(window.location.hash)
    if (el) { el.scrollIntoView(true) }
  }
}

// header scroll observer
function detectScrollForHeader() {
  const tracker = document.querySelector('.header-scroll-tracker')
  const body = document.querySelector('body')
  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) { body.classList.remove('scrolled') }
      else { body.classList.add('scrolled') }
    })
  }
  const observer = new IntersectionObserver(callback, { root: body, threshold: 0.1 })
  if (tracker) { observer.observe(tracker) }
}

// sharer.js
import 'sharer.js'
function initSharer() { Sharer.init() }

// plausible.io + Turbo
function trackVisit() {
  try { plausible('pageview') }
  catch(err) { console.log('Plausible: not available.') }
}

// make all external links open in new tab
document.arrive('.richtext', { existing: true }, function(el) {
  const selector = "a[href^='http']:not([href^='http://www.accord-ece.com']):not([href^='http://accord-ece.com']):not([href^='https://www.accord-ece.com']):not([href^='https://accord-ece.com'])"
  el.querySelectorAll(selector).forEach((e) => { e.setAttribute("target", "_blank") })
})

function initSalAnimations() {
  sal({ once: false })
}

// on-load handlers
document.addEventListener('turbo:load', () => {
  trackVisit()
  initSharer()
  detectScrollForHeader()
  scrollToAnchor()
  // setVhHeight()
  setNaviMargin()
  initSalAnimations()
})

window.addEventListener('popstate', scrollToAnchor)

import '~/javascript/home'
import '~/javascript/map'
import '~/javascript/swipers'

// CSS Styles
import '~/stylesheets/website.scss'
