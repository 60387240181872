import iconImg from '~/images/marker.png'

document.arrive('.office-map', { existing: true }, function(el) {
  var mapStyle = [
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#011066"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#5580aa"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#405783"
            }
        ]
    },
    {
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "weight": 0.5
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#27abda"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#272664"
            }
        ]
    }
  ]
  var coords = new google.maps.LatLng(el.dataset.latitude, el.dataset.longitude)
  var opts = {
    auto_adjust: true,
    auto_zoom: false,
    center: coords,
    zoom: 17,
    styles: mapStyle,
    streetViewControl: false,
    mapTypeControl: false
  }
  var map = new google.maps.Map(el, opts)
  var marker = new google.maps.Marker({ position: coords, map: map, title: 'Accord Group', icon: { url: iconImg } })
  var info = new google.maps.InfoWindow({ content: `<strong>${el.dataset.name}</strong><br>${el.dataset.address}` })
  google.maps.event.addListener(marker, 'click', function () {
    info.open(map, marker)
  })
})

// empty callback handler to make Google Maps API happy
window.initMap = () => {}
